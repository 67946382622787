.master-vd {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px 0;
  background-color: "red" !important;
}

#button-container {
  display: flex;
  justify-content: space-around;
}
.success-button-container {
  margin-top: 2%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.secondary-button {
  font-size: 1rem;
  margin-top: 2%;
  color: black;
  opacity: 60%;
  text-decoration: none;
}

.card-vd.mdl-card {
  width: 512px;
  border: none;
  /* box-shadow: none; */
}

.card-body {
  margin-top: 10%;
}

.card-vd > .mdl-card__title {
  color: #fff;
  height: 176px;
}
.card-vd > .mdl-card__menu {
  color: #fff;
}

.header-vd .by-dv {
  font-size: 13px;
  vertical-align: bottom;
  line-height: 35px;
  padding-left: 5px;
}

.button-vd {
  display: flex;
}
.next-vd {
  margin-left: auto;
}

.next-vd {
  text-align: center;
}
.googleplay-button-vd img {
  height: 60px;
}
.error {
  color: #f15249;
  text-align: left;
}
.successInvitation {
  text-align: left;
}
