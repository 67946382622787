progress,
sub,
sup {
  vertical-align: baseline;
}
button,
hr,
input {
  overflow: visible;
}
[type="checkbox"],
[type="radio"],
legend {
  padding: 0;
}
[aria-disabled],
html {
  cursor: default;
}
figcaption,
menu,
article,
aside,
details,
figure,
footer,
header,
main,
nav,
section,
summary {
  display: block;
}
audio,
canvas,
progress,
video {
  display: inline-block;
}
audio:not([controls]) {
  display: none;
  height: 0;
}
[hidden],
template {
  display: none;
}
*,
::after,
::before {
  background-repeat: no-repeat;
  box-sizing: inherit;
}
::after,
::before {
  text-decoration: inherit;
  vertical-align: inherit;
}
html {
  box-sizing: border-box;
  font-family: sans-serif;
  line-height: 1.5;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
body {
  margin: 0;
}
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}
small,
sub,
sup {
  font-size: 83.3333%;
}
hr {
  height: 0;
}
nav ol,
nav ul {
  list-style: none;
}
abbr[title] {
  border-bottom: 1px dotted;
  text-decoration: none;
}
b,
strong {
  font-weight: bolder;
}
dfn {
  font-style: italic;
}
mark {
  background-color: #ff0;
  color: #000;
}
sub,
sup {
  line-height: 0;
  position: relative;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
::-moz-selection {
  background-color: #b3d4fc;
  color: #000;
  text-shadow: none;
}
::selection {
  background-color: #b3d4fc;
  color: #000;
  text-shadow: none;
}
audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}
img {
  border-style: none;
}
svg {
  fill: currentColor;
}
svg:not(:root) {
  overflow: hidden;
}
a {
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}
a:hover {
  outline-width: 0;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
button,
input,
select,
textarea {
  background-color: transparent;
  border-style: none;
  color: inherit;
  font-size: 1em;
  margin: 0;
}
button,
input {
}
button,
select {
  text-transform: none;
}
[type="submit"],
[type="reset"],
button,
html [type="button"] {
  -webkit-appearance: button;
}
::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
:-moz-focusring {
  outline: ButtonText dotted 1px;
}
fieldset {
  border: 1px solid silver;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}
legend {
  display: table;
  max-width: 100%;
  white-space: normal;
}
textarea {
  overflow: auto;
  resize: vertical;
}
::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}
[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}
::-webkit-search-cancel-button,
::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54;
}
::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}
[aria-busy="true"] {
  cursor: progress;
}
[aria-controls] {
  cursor: pointer;
}
[tabindex],
a,
area,
button,
input,
label,
select,
textarea {
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}
[hidden][aria-hidden="false"] {
  clip: rect(0, 0, 0, 0);
  display: inherit;
  position: absolute;
}
[hidden][aria-hidden="false"]:focus {
  clip: auto;
} /*# sourceMappingURL=sanitize.min.css.map */

@import url("https://fonts.googleapis.com/css?family=Roboto");
@import url("https://fonts.googleapis.com/css?family=Indie+Flower");

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: lighter;
}

.button-container {
  display: flex;
  justify-content: space-around;
}

button {
  font-size: 20px;
  border: none;
  color: white;
  background-color: #f44336;
  margin-top: 10px;
  padding: 10px;
  cursor: pointer;
  border-radius: 2px;
}

button:disabled {
  background-color: #eceff1;
  cursor: not-allowed;
  color: white;
}
.box {
  display: flex;
  flex-wrap: wrap;
  font-family: "Roboto", sans-serif;
  color: #212121;
  /*border: 5px solid purple;*/
}

.box > * {
  flex: 1 1 100vw;
}

header {
  text-align: center;
  background-color: #f44336;
  color: white;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
  margin-bottom: 5px;
  padding: 10px 20px;
}
header h1 {
  margin: 0;
  text-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
}

.cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.card {
  flex: 0 1 500px;
  min-height: 500px;
  /*border: 5px solid lightblue;*/
  margin: 20px 15px;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
  border-radius: 2px;
}

/* ----------------------------
	NotFound
---------------------------- */

.notFound {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  margin: 0;
  background: #eceff1;
}

/* ----------------------------
	Admin
---------------------------- */

.admin-form {
  max-width: 500px;
  font-size: 24px;
}
.admin-form > * {
  width: 100%;
}
.admin-form > input {
  box-sizing: border-box;
  font-size: 24px;
  padding: 5px;
  border: 5px solid red;
  border-width: 0 0 5px 0;
  outline: none;
}

.card > button {
  width: 100%;
  background: red;
}
.error {
  color: #f15249;
}
.successPasswordReset {
  text-align: left;
}
.successPasswordReset > h5 {
  margin: 0px 0 16px;
}
.mdl-card__supporting-text > h5 {
  margin: 0px 0 16px;
}
