.demo-card-wide.mdl-card {
  width: 100%;
  cursor: pointer;
}

.demo-card-wide > .mdl-card__title {
  color: white;
  height: 176px;
}

.story-title {
  font-size: 12px;
  font-weight: 600;
  text-shadow: rgba(0, 0, 0, 0.14) 1px 1px 1px;
  padding: 1px;
}

.mdl-card--border {
  text-align: center;
}
.mdl-cell > .mdl-button {
  font-size: 12px;
  margin: auto;
}
