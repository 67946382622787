@import url(https://fonts.googleapis.com/css?family=Roboto);
@import url(https://fonts.googleapis.com/css?family=Indie+Flower);
@font-face {
  font-family: "Quicksand";
  src: url(/static/media/Quicksand-Regular.6cbafd2c.ttf) format("truetype");
}

body {
  margin: 0;
  font-family: "Quicksand", "Roboto" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.master-vd {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px 0;
}
.mdl-card__supporting-text {
  width: 100% !important;
}

.card-vd > .mdl-card__title {
  color: #fff;
  height: 176px;
}
.card-vd > .mdl-card__menu {
  color: #fff;
}

.header-vd .by-dv {
  font-size: 13px;
  vertical-align: bottom;
  line-height: 35px;
  padding-left: 5px;
}

.button-vd {
  display: flex;
  justify-content: flex-end;
}
.next-vd {
  margin-left: auto;
}

.join-form-vd {
  text-align: left;
  width: 100%;
}
.join-form-vd > .mdl-textfield {
  width: 100%;
  padding: 10px;
  outline: none;
}
.join-form-vd > .button-vd {
  width: 100%;
  justify-content: center;
  font-size: 20px;
  border: none;
  color: white;
  background-color: red;
  margin-top: 10px;
  padding: 10px;
  cursor: pointer;
  border-radius: 2px;
}
.googleplay-button-vd img {
  height: 60px;
}
.card > button {
  width: 100%;
  background: red;
}

.successPasswordForgot {
  text-align: left;
}
.hello {
  text-align: center;
}
.mdl-card__supporting-text > h5 {
  margin: 0px 0 16px;
}

.successPasswordForgot > h5 {
  margin: 0px 0 16px;
}

.master-vd {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px 0;
}

.not-found-container {
  flex-direction: column;
}

.not-found-container > .mdl-card__supporting-text {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.card-vd > .mdl-card__title {
  color: #fff;
  height: 176px;
}
.card-vd > .mdl-card__menu {
  color: #fff;
}

.header-vd .by-dv {
  font-size: 13px;
  vertical-align: bottom;
  line-height: 35px;
  padding-left: 5px;
}

.button-vd {
  display: flex;
}
.next-vd {
  margin-left: auto;
}

.join-form-vd {
  text-align: center;
  width: 100%;
}
.join-form-vd > .mdl-textfield {
  width: 100%;
  padding: 10px;
  outline: none;
}
.join-form-vd > .button-vd {
  width: 100%;
  justify-content: center;
  font-size: 20px;
  border: none;
  color: white;
  background-color: red;
  margin-top: 10px;
  padding: 10px;
  cursor: pointer;
  border-radius: 2px;
}
.googleplay-button-vd img {
  height: 60px;
}
.card > button {
  width: 100%;
  background: red;
}
.errorNotFound {
  text-align: left;
}
.errorNotFound > h5 {
  margin: 0px 0 16px;
}

.errorNotFound {
  margin: auto;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

#header-container {
  border-bottom: 1px solid #00818d;
  padding: 0.5rem;
  max-width: 532px;
}

#img-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 80%;
  margin-bottom: 0.5rem;
}

#emoji-boucle {
  margin-top: -0.5rem;
  width: 20%;
  height: 20%;
}
#logotype {
  width: 55%;
  height: 55%;
}
#bookinou {
  height: 25%;
  width: 25%;
  -webkit-transform: rotate(-20deg);
          transform: rotate(-20deg);
}

.text-container {
  width: 80%;
}

#title {
  width: 50%;
  font-size: 2rem;
}

#subtitle {
  margin-left: 0.2rem;
}

progress,
sub,
sup {
  vertical-align: baseline;
}
button,
hr,
input {
  overflow: visible;
}
[type="checkbox"],
[type="radio"],
legend {
  padding: 0;
}
[aria-disabled],
html {
  cursor: default;
}
figcaption,
menu,
article,
aside,
details,
figure,
footer,
header,
main,
nav,
section,
summary {
  display: block;
}
audio,
canvas,
progress,
video {
  display: inline-block;
}
audio:not([controls]) {
  display: none;
  height: 0;
}
[hidden],
template {
  display: none;
}
*,
::after,
::before {
  background-repeat: no-repeat;
  box-sizing: inherit;
}
::after,
::before {
  text-decoration: inherit;
  vertical-align: inherit;
}
html {
  box-sizing: border-box;
  font-family: sans-serif;
  line-height: 1.5;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
body {
  margin: 0;
}
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}
small,
sub,
sup {
  font-size: 83.3333%;
}
hr {
  height: 0;
}
nav ol,
nav ul {
  list-style: none;
}
abbr[title] {
  border-bottom: 1px dotted;
  text-decoration: none;
}
b,
strong {
  font-weight: bolder;
}
dfn {
  font-style: italic;
}
mark {
  background-color: #ff0;
  color: #000;
}
sub,
sup {
  line-height: 0;
  position: relative;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
::selection {
  background-color: #b3d4fc;
  color: #000;
  text-shadow: none;
}
audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}
img {
  border-style: none;
}
svg {
  fill: currentColor;
}
svg:not(:root) {
  overflow: hidden;
}
a {
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}
a:hover {
  outline-width: 0;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
button,
input,
select,
textarea {
  background-color: transparent;
  border-style: none;
  color: inherit;
  font-size: 1em;
  margin: 0;
}
button,
input {
}
button,
select {
  text-transform: none;
}
[type="submit"],
[type="reset"],
button,
html [type="button"] {
  -webkit-appearance: button;
}
::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
:-moz-focusring {
  outline: ButtonText dotted 1px;
}
fieldset {
  border: 1px solid silver;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}
legend {
  display: table;
  max-width: 100%;
  white-space: normal;
}
textarea {
  overflow: auto;
  resize: vertical;
}
::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}
[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}
::-webkit-search-cancel-button,
::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54;
}
::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}
[aria-busy="true"] {
  cursor: progress;
}
[aria-controls] {
  cursor: pointer;
}
[tabindex],
a,
area,
button,
input,
label,
select,
textarea {
  touch-action: manipulation;
}
[hidden][aria-hidden="false"] {
  clip: rect(0, 0, 0, 0);
  display: inherit;
  position: absolute;
}
[hidden][aria-hidden="false"]:focus {
  clip: auto;
} /*# sourceMappingURL=sanitize.min.css.map */

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: lighter;
}

.button-container {
  display: flex;
  justify-content: space-around;
}

button {
  font-size: 20px;
  border: none;
  color: white;
  background-color: #f44336;
  margin-top: 10px;
  padding: 10px;
  cursor: pointer;
  border-radius: 2px;
}

button:disabled {
  background-color: #eceff1;
  cursor: not-allowed;
  color: white;
}
.box {
  display: flex;
  flex-wrap: wrap;
  font-family: "Roboto", sans-serif;
  color: #212121;
  /*border: 5px solid purple;*/
}

.box > * {
  flex: 1 1 100vw;
}

header {
  text-align: center;
  background-color: #f44336;
  color: white;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
  margin-bottom: 5px;
  padding: 10px 20px;
}
header h1 {
  margin: 0;
  text-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
}

.cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.card {
  flex: 0 1 500px;
  min-height: 500px;
  /*border: 5px solid lightblue;*/
  margin: 20px 15px;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
  border-radius: 2px;
}

/* ----------------------------
	NotFound
---------------------------- */

.notFound {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  margin: 0;
  background: #eceff1;
}

/* ----------------------------
	Admin
---------------------------- */

.admin-form {
  max-width: 500px;
  font-size: 24px;
}
.admin-form > * {
  width: 100%;
}
.admin-form > input {
  box-sizing: border-box;
  font-size: 24px;
  padding: 5px;
  border: 5px solid red;
  border-width: 0 0 5px 0;
  outline: none;
}

.card > button {
  width: 100%;
  background: red;
}
.error {
  color: #f15249;
}
.successPasswordReset {
  text-align: left;
}
.successPasswordReset > h5 {
  margin: 0px 0 16px;
}
.mdl-card__supporting-text > h5 {
  margin: 0px 0 16px;
}

.master-vd {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px 0;
}
.card-vd > .mdl-card__title {
  color: #fff;
  height: 176px;
}
.card-vd > .mdl-card__menu {
  color: #fff;
}

.header-vd .by-dv {
  font-size: 13px;
  vertical-align: bottom;
  line-height: 35px;
  padding-left: 5px;
}

.button-vd {
  display: flex;
}
.next-vd {
  margin-left: auto;
}

.join-form-vd {
  text-align: center;
  width: 100%;
}
.join-form-vd > .mdl-textfield {
  width: 100%;
  padding: 10px;
  outline: none;
}
.join-form-vd > .button-vd {
  width: 100%;
  justify-content: center;
  font-size: 20px;
  border: none;
  color: white;
  background-color: red;
  margin-top: 10px;
  padding: 10px;
  cursor: pointer;
  border-radius: 2px;
}
.googleplay-button-vd img {
  height: 60px;
}
.card > button {
  width: 100%;
  background: red;
}

.error {
  color: #f15249;
}
.mdl-card__supporting-text > h5 {
  margin: 0px 0 16px;
}

.master-vd {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px 0;
  background-color: "red" !important;
}

#button-container {
  display: flex;
  justify-content: space-around;
}
.success-button-container {
  margin-top: 2%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.secondary-button {
  font-size: 1rem;
  margin-top: 2%;
  color: black;
  opacity: 60%;
  text-decoration: none;
}

.card-vd.mdl-card {
  width: 512px;
  border: none;
  /* box-shadow: none; */
}

.card-body {
  margin-top: 10%;
}

.card-vd > .mdl-card__title {
  color: #fff;
  height: 176px;
}
.card-vd > .mdl-card__menu {
  color: #fff;
}

.header-vd .by-dv {
  font-size: 13px;
  vertical-align: bottom;
  line-height: 35px;
  padding-left: 5px;
}

.button-vd {
  display: flex;
}
.next-vd {
  margin-left: auto;
}

.next-vd {
  text-align: center;
}
.googleplay-button-vd img {
  height: 60px;
}
.error {
  color: #f15249;
  text-align: left;
}
.successInvitation {
  text-align: left;
}

#button-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5%;
  padding: 0 5%;
}

.error {
  color: #f15249;
}

.master-vd {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px 0;
}

.card-vd > .mdl-card__title {
  color: #fff;
  height: 176px;
}
.error {
  color: #f15249;
  text-align: left;
}

.successStory {
  text-align: left;
}

.demo-card-wide.mdl-card {
  width: 100%;
  cursor: pointer;
}

.demo-card-wide > .mdl-card__title {
  color: white;
  height: 176px;
}

.story-title {
  font-size: 12px;
  font-weight: 600;
  text-shadow: rgba(0, 0, 0, 0.14) 1px 1px 1px;
  padding: 1px;
}

.mdl-card--border {
  text-align: center;
}
.mdl-cell > .mdl-button {
  font-size: 12px;
  margin: auto;
}

.master-vd {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px 0;
}

.card-vd > .mdl-card__title {
  color: #fff;
  height: 176px;
}
.card-vd > .mdl-card__menu {
  color: #fff;
}

.header-vd .by-dv {
  font-size: 13px;
  vertical-align: bottom;
  line-height: 35px;
  padding-left: 5px;
}

.button-vd {
  display: flex;
}
.next-vd {
  margin-left: auto;
}

.join-form-vd {
  text-align: center;
  width: 100%;
}
.join-form-vd > .mdl-textfield {
  width: 100%;
  padding: 10px;
  outline: none;
}
.join-form-vd > .button-vd {
  width: 100%;
  justify-content: center;
  font-size: 20px;
  border: none;
  color: white;
  background-color: red;
  margin-top: 10px;
  padding: 10px;
  cursor: pointer;
  border-radius: 2px;
}
.googleplay-button-vd img {
  height: 60px;
}
.card > button {
  width: 100%;
  background: red;
}
.error {
  color: #f15249;
}

