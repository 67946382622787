.master-vd {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px 0;
}

.card-vd > .mdl-card__title {
  color: #fff;
  height: 176px;
}
.error {
  color: #f15249;
  text-align: left;
}

.successStory {
  text-align: left;
}
