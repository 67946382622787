.master-vd {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px 0;
}

.card-vd > .mdl-card__title {
  color: #fff;
  height: 176px;
}
.card-vd > .mdl-card__menu {
  color: #fff;
}

.header-vd .by-dv {
  font-size: 13px;
  vertical-align: bottom;
  line-height: 35px;
  padding-left: 5px;
}

.button-vd {
  display: flex;
}
.next-vd {
  margin-left: auto;
}

.join-form-vd {
  text-align: center;
  width: 100%;
}
.join-form-vd > .mdl-textfield {
  width: 100%;
  padding: 10px;
  outline: none;
}
.join-form-vd > .button-vd {
  width: 100%;
  justify-content: center;
  font-size: 20px;
  border: none;
  color: white;
  background-color: red;
  margin-top: 10px;
  padding: 10px;
  cursor: pointer;
  border-radius: 2px;
}
.googleplay-button-vd img {
  height: 60px;
}
.card > button {
  width: 100%;
  background: red;
}
.error {
  color: #f15249;
}
