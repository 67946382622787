#header-container {
  border-bottom: 1px solid #00818d;
  padding: 0.5rem;
  max-width: 532px;
}

#img-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 80%;
  margin-bottom: 0.5rem;
}

#emoji-boucle {
  margin-top: -0.5rem;
  width: 20%;
  height: 20%;
}
#logotype {
  width: 55%;
  height: 55%;
}
#bookinou {
  height: 25%;
  width: 25%;
  transform: rotate(-20deg);
}

.text-container {
  width: 80%;
}

#title {
  width: 50%;
  font-size: 2rem;
}

#subtitle {
  margin-left: 0.2rem;
}
