#button-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5%;
  padding: 0 5%;
}

.error {
  color: #f15249;
}
